import { DefaultConfigOptions } from '@formkit/vue';
import { de, en } from '@formkit/i18n';

// Only needed until beta.5 is out:
if (typeof global !== 'undefined') {
  (global as Record<string, any>).File = function () {};
}

const config: DefaultConfigOptions = {
    locales: {de, en},
    locale: 'de',
};

export default config;
