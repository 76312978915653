import { default as _200R2TRAwZNFYMeta } from "/data/ws_carmaxx/carmaxx-reservierung-online/pages/200.vue?macro=true";
import { default as _404qEfnTh8x2yMeta } from "/data/ws_carmaxx/carmaxx-reservierung-online/pages/404.vue?macro=true";
import { default as confirm1VCovcRd1rMeta } from "/data/ws_carmaxx/carmaxx-reservierung-online/pages/confirm.vue?macro=true";
import { default as indexYFv6lpdCZRMeta } from "/data/ws_carmaxx/carmaxx-reservierung-online/pages/index.vue?macro=true";
export default [
  {
    name: "200___de___default",
    path: "/200",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/200.vue")
  },
  {
    name: "200___de",
    path: "/de/200",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/200.vue")
  },
  {
    name: "200___en",
    path: "/en/200",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/200.vue")
  },
  {
    name: "200___dk",
    path: "/dk/200",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/200.vue")
  },
  {
    name: "404___de___default",
    path: "/404",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/404.vue")
  },
  {
    name: "404___de",
    path: "/de/404",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/404.vue")
  },
  {
    name: "404___en",
    path: "/en/404",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/404.vue")
  },
  {
    name: "404___dk",
    path: "/dk/404",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/404.vue")
  },
  {
    name: "confirm___de___default",
    path: "/confirm",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/confirm.vue")
  },
  {
    name: "confirm___de",
    path: "/de/confirm",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/confirm.vue")
  },
  {
    name: "confirm___en",
    path: "/en/confirm",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/confirm.vue")
  },
  {
    name: "confirm___dk",
    path: "/dk/confirm",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/confirm.vue")
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/index.vue")
  },
  {
    name: "index___dk",
    path: "/dk",
    component: () => import("/data/ws_carmaxx/carmaxx-reservierung-online/pages/index.vue")
  }
]