<script setup>
import { inject, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { useToast } from 'tailvue'

// set formkit lang to i18n current lang
const lastLangKey = 'carmaxx.last-lang';
const nuxtApp = useNuxtApp();
const lang = nuxtApp.$i18n.locale.value;
const config = inject(Symbol.for('FormKitConfig'));

console.debug('app.vue init', lang);

const lastLang = localStorage.getItem(lastLangKey) || '/de';

if ((location.pathname == '/') && (lastLang != ('/' + lang))) {
  setTimeout(() => {
    location.assign(lastLang);
  }, 200);
} else {
  localStorage.setItem(lastLangKey, '/' + lang);
}

config.locale = lang;

var onInstallClick = (ev) => {

  ev.preventDefault();

  if (window.BeforeInstallPromptEvent instanceof Function) {
    $pwa.install();
  }

  return false;
}

const { $pwa } = useNuxtApp();

const toast = useToast();

onMounted(() => {
  console.debug('pwa info:', $pwa, $pwa.showInstallPrompt, $pwa.isInstalled);
});

function mainErrorLogger(err) {
  console.log('mainErrorLogger', err);
  Sentry.captureException(err);
}

function switchLang(ev) {
  ev.preventDefault();
  console.debug('switch language', ev);
  const href = ev.target.href || ev.target.parentElement.getAttribute('href');

  localStorage.setItem(lastLangKey, href);

  location.assign(href);
  return false;
}

function canInstall() {
  return (window.BeforeInstallPromptEvent instanceof Function) && !($pwa.isInstalled || (window.navigator.standalone == true));
}

</script>
<template>
  <div>
    <VitePwaManifest />
    <div v-show="$pwa.needRefresh" class="rounded border-2 border-amber-600 text-lg text-amber-600 p-3 my-5 bg-amber-100">
      <span>
        New version available, click on reload button to update.
      </span>
      <button @click="$pwa.updateServiceWorker()"
        class="ml-5 rounded bg-amber-600 text-amber-100 px-4 hover:bg-amber-700">
        Reload
      </button>
    </div>
    <div class="header grid grid-cols-6 gap-4">
      <a href="https://www.c-maxx.de/" class="mr-5 md:mr-0 col-span-3 md:col-span-4"><img src="/images/carmaxx.png"
          width="212" /></a>
      <div class="col-span-2 md:col-span-1">
        <ul class="lang-switch flex gap-4">
          <li v-if="(('/' + lang) != '/de')">
            <a href="/de" @click="switchLang"><img src="/images/icons/de.png" /></a>
          </li>
          <li v-if="(('/' + lang) != '/en')">
            <a href="/en" @click="switchLang"><img src="/images/icons/en.png" /></a>
          </li>
          <li v-if="(('/' + lang) != '/dk')">
            <a href="/dk" @click="switchLang"><img src="/images/icons/dk.png" /></a>
          </li>
        </ul>
      </div>
      <div class="text-right">
        <a href="#" @click="onInstallClick" class="install-btn inline-block" v-if="canInstall()">
          <span class="icon-bookmark"></span>
        </a>
      </div>
    </div>
    <ClientOnly fallback-tag="span" fallback="Loading...">
      <NuxtLoadingIndicator />
      <NuxtLayout>
        <NuxtErrorBoundary @error="mainErrorLogger">
          <NuxtPage />
          <template #error="{ error, clearError }">
            <div class="auto-error-box">
              <h1 class="px-4 py-2">{{ $t('error_occurred') }}:</h1>
              <pre class="alert alert-error">
                {{ error }}
                <button @click="clearError" class="error-close-btn btn btn-primary">&#x2716;</button>
              </pre>
            </div>
          </template>
        </NuxtErrorBoundary>
      </NuxtLayout>
    </ClientOnly>
  </div>
</template>
<style>

.auto-error-box {
  .auto-error-box {
    position: absolute;
    top: 1em;
    right: 1em;
  }
}

.header {
  background-color: #fff;
  padding: 1em;
  border-bottom: 4px solid #8c7f59;
  border-radius: 4px 4px 0 0;
  margin-top: 8px;
}

.install-btn {
  padding: 8px;
  margin-top: -14px;
  margin-bottom: -9px;

  .icon-bookmark {
    display: block;
    width: 36px;
    aspect-ratio: 1;
    mask-image: url(/images/icons/add-bookmark-icon.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: #8c7f59;
  }

  &:hover,
  &:focus,
  &:active {

    background-color: #8c7f59;

    .icon-bookmark {
      background-color: #fff;
    }
  }
}
</style>
